import React, { Component } from 'react';
import { css } from "@emotion/core";
import { Router, Route, IndexRoute, Link, IndexLink, browserHistory } from 'react-router';

class MessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      active: false,
      showAll: false,
      maxLength: 90
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.message.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditMessage(this.props.message, this.state.editText);

    this.setState({ editMode: false });
  };

  accordionExpand = (e) => {
    var acc = document.getElementById(e.currentTarget.id);
    var messageItem = document.getElementById(e.currentTarget.id).parentElement;
    var accordionButtons = document.getElementsByClassName('accordion');
    var panel = acc.getElementsByClassName('messageBody');
    for (var i = 0; i < accordionButtons.length; i++) {
      var aPanel = accordionButtons[i].nextElementSibling.nextElementSibling;
      if (accordionButtons[i] === acc) {
        //accordionButtons[i].classList.add("active");
        //messageItem.classList.add("active");
        if(messageItem.classList.contains("active")) {
          messageItem.classList.remove("active")
        } else {
          messageItem.classList.add("active")
          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
          //aPanel.style.maxHeight = null;
        }
        
      } else {
        accordionButtons[i].parentElement.classList.remove("active");
        if(accordionButtons[i].parentElement.classList.contains("active")) {
          accordionButtons[i].parentElement.classList.remove("active")
        }
        //accordionButtons[i].classList.toggle("active");
        aPanel.style.maxHeight = null;
        //messageItem.classList.toggle("active");
      }
    }
    //messageItem.classList.toggle("active");
    
    /*if (panel[0].style.maxHeight) {
      //const setLength = this.setState({maxLength: 90});
      
      const setLength = new Promise(function(resolve, reject) {
        resolve(panel[0].style.maxHeight = null);
      });
      setLength.then(function() {
        setTimeout(function() {
          this.setState({maxLength: 90});
          panel[0].style.maxHeight = null
        }.bind(this), 300);
      }.bind(this));
      //panel[0].style.maxHeight = null;
      
    } else {
      //const setLength = this.setState({maxLength: 2000});
      
      const setLength = new Promise(function(resolve, reject) {
        //resolve(this.setState({maxLength: 2000}));
        resolve(this.setState({maxLength: 2000}), setTimeout(function() {this.setState({maxLength: 2000})}.bind(this),500));
      }.bind(this));
      setLength.then(function() {
        panel[0].style.maxHeight = panel[0].scrollHeight + "px";
        setTimeout(function() {panel[0].style.maxHeight = panel[0].scrollHeight + "px";},600)
        
      });
        //this.setState({maxLength: 90});
        //this.setState({maxLength: 2000});
        
    }*/
  }

  archiveConfirm(e) {
    var cPopup = e.currentTarget.getAttribute('data-item');
    var hPopupArchive = document.getElementsByClassName('archiveConfirmPopup');
    var hPopupDelete = document.getElementsByClassName('deleteConfirmPopup');
    var pConfirm = document.getElementById(cPopup);
    //var pHide = document.getElementById(hPopup);
    for (var i = 0; i < hPopupArchive.length; ++i) {
        var item = hPopupArchive[i];
        if (item.id !== cPopup) {
          item.classList.remove('active');
        }
    }
    for (var i = 0; i < hPopupDelete.length; ++i) {
      var itemd = hPopupDelete[i];
      if (itemd.id !== cPopup) {
        itemd.classList.remove('active');
      }
    }
    if (pConfirm.classList.contains('active')) {
      pConfirm.classList.remove('active');
    } else {
      pConfirm.classList.add('active');
    }    
  }

  click() {
		this.setState({active: true});
	}

  togAccord(e) {
    //const id = event.target.id;
    const acc = document.getElementById(e.currentTarget.id);
    const id = e.target.getAttribute("id");
    console.log(acc);
    const accordionButtons = document.getElementsByClassName('accordion');
    const panel = acc.nextElementSibling.nextElementSibling;
    for (var i = 0; i < accordionButtons.length; i++) {
      var aPanel = accordionButtons[i].nextElementSibling.nextElementSibling;
      if (accordionButtons[i] == acc) {
        //do nothing
      } else {
        accordionButtons[i].classList.remove("active");
        aPanel.style.maxHeight = null;
      }
    }
    acc.classList.toggle("active");
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }


  render() {
    const { authUser, message, onRemoveMessage, onChangeArchiveTrue,togAccord, e } = this.props;
    const { editMode, editText, showAll, maxLength } = this.state;
    
    return (
      <React.Fragment>
      {authUser.uid === message.userId && (

      <li id={'message'+message.uid} key={'message'+message.uid} className="messageItem flexContainer activeP activePI ">
      
        <React.Fragment>
          <button value='pModal' className={'messageInfo accordion messageSTATUS_' + (message.mrkREAD ? 'hasREAD' : 'notREAD')} id={'accordion'+message.uid} onClick={(e) => { this.props.onChangeArchiveTrue(message.uid); this.accordionExpand(e);}}>
            <h3>Message: {message.msgTitle} </h3>
              
              <p className="messageBody" dangerouslySetInnerHTML={{__html: message.text}}/>
          </button>


          {/*
          <button value='pModal' className={(this.state.active && 'active')+' messageInfo accordion messageSTATUS_' + (message.mrkREAD ? 'hasREAD' : 'notREAD')} onClick={ () => {this.setState({active: !this.state.active}); onChangeArchiveTrue(message.uid)}}>
          <button value='pModal' className={'messageInfo accordion messageSTATUS_' + (message.mrkREAD ? 'hasREAD' : 'notREAD')} id={'accordion'+message.uid} onClick={() => onChangeArchiveTrue(e)}>
          <button value='pModal' className={'messageInfo accordion messageSTATUS_' + (message.mrkREAD ? 'hasREAD' : 'notREAD')} id={'accordion'+message.uid} onClick={e => {this.togAccord(e);onChangeArchiveTrue(message.uid)}}>
            <h3>Message: {message.msgTitle} </h3>
            <span className="notifCutoff">{message.text}</span>
          </button>
          */}

          <div className="portfolioButtons">
            {/*
            {authUser.uid === message.userId && (
              <button type="button" onClick={() => onChangeArchiveTrue(message.uid)}>Mark Read</button>
            )}
            */}
            
            <button className="buttonDelete" data-item={'deleteConfirm'+message.uid} data-hide={'archiveConfirm'+message.uid} onClick={e => this.archiveConfirm(e)}><span className="icon-TrashBin"></span></button>
          </div>

          <div className="portfolioProductList messageContainerList" onChange={() => this.onChangeArchiveTrue(message.uid)}>
            <div key={message.uid} className={'message-carried-'+message.uid}>
              {/*<h3>Message: {message.msgTitle} </h3>*/}
              
              

            </div>
          </div>

          <div id={'deleteConfirm'+message.uid} className="deleteConfirmPopup">
            <button className="closeArea" data-item={'deleteConfirm'+message.uid} onClick={e => this.archiveConfirm(e)}></button>
            <div className="inner">
              <h3>Are you sure you want to delete this message?</h3>
              <span>Once a message is deleted, it cannot be recovered.</span>
              <div className="buttons">
                <button type="button" className="buttonDelete" onClick={() => onRemoveMessage(message.uid)}>Yes</button>
                <button data-item={'deleteConfirm'+message.uid} onClick={e => this.archiveConfirm(e)}>No</button>
              </div>
            </div>
          </div>   
        </React.Fragment>
      </li>
      )}
      </React.Fragment>
    );
  }
}

export default MessageItem;